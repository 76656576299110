















































































































import { Component, Emit } from 'vue-property-decorator'
import { BModal, BButton, BSpinner, BListGroup, BListGroupItem, VBModal } from 'bootstrap-vue'
import BaseFilterComponent from '@infinity/shared/components/BaseFilter.vue'
import WebComponent from '@infinity/shared/decorators/WebComponent'
import { Api, RequestMethod } from '@infinity/shared/helpers/api'
import { AuthUtil } from '@infinity/shared/utils/auth'
import AddKeywordGroup from '@infinity/shared/components/AddKeywordGroup.vue'
import axios from 'axios'
import { FilterOperand } from '@infinity/shared/helpers/filter'
import { PopoverEvent } from '@infinity/shared/components/layout/InfinityPopover.vue'

export class KeywordGroup {
  private keywordGroupId = 0
  private keywordGroupName = ''
  private keywordGroupStatus = 0

  constructor (id = '0') {
    this.keywordGroupId = parseInt(id)
  }

  getKeywordGroupId (): number {
    return this.keywordGroupId
  }

  getKeywordGroupName (): string {
    return this.keywordGroupName
  }

  getKeywordGroupStatus (): number {
    return this.keywordGroupStatus
  }

  isDeleted (): boolean {
    return this.keywordGroupStatus === 410
  }

  enrich (keywordGroup: KeywordGroup) {
    this.keywordGroupName = keywordGroup.getKeywordGroupName()
    this.keywordGroupStatus = keywordGroup.getKeywordGroupStatus()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromApi (model: any): KeywordGroup {
    this.keywordGroupId = parseInt(model.keywordGroupId)
    this.keywordGroupName = model.keywordGroupName
    this.keywordGroupStatus = parseInt(model.keywordGroupStatus)

    return this
  }
}

export enum KeywordGroupClauseType {
  And,
  Not
}

export class KeywordGroupClause {
  private type: KeywordGroupClauseType
  private keywordGroups: KeywordGroup[] = []

  constructor (type: KeywordGroupClauseType) {
    this.type = type
  }

  addKeywordGroup (keywordGroup: KeywordGroup) {
    const index = this.keywordGroups.indexOf(keywordGroup)
    if (index === -1) {
      this.keywordGroups.push(keywordGroup)
    }
  }

  hasKeywordGroup (keywordGroup: KeywordGroup) {
    return this.keywordGroups.indexOf(keywordGroup) > -1
  }

  removeKeywordGroup (keywordGroup: KeywordGroup) {
    const index = this.keywordGroups.indexOf(keywordGroup)
    if (index > -1) {
      this.keywordGroups.splice(index, 1)
    }
  }

  getKeywordGroups () {
    return this.keywordGroups
  }

  setType (type: KeywordGroupClauseType) {
    this.type = type
  }

  getType () {
    return this.type
  }

  getNumOfKeywordGroups () {
    return this.keywordGroups.length
  }

  isAndClause () {
    return this.type === KeywordGroupClauseType.And
  }

  isNotClause () {
    return this.type === KeywordGroupClauseType.Not
  }
}

@WebComponent('filter-callkeywordgroups')
@Component({
  components: {
    BModal,
    BButton,
    BSpinner,
    BListGroup,
    BListGroupItem,
    AddKeywordGroup
  },
  directives: {
    'b-modal': VBModal
  }
})
export default class KeywordGroupFilterComponent extends BaseFilterComponent {
  static filterName = 'Keyword Group'
  readonly KeywordGroupClauseType = KeywordGroupClauseType

  isLoading = false
  isLoadingKeywordGroups = false
  selectedKeywordGroups: number[] = []
  keywordGroups: KeywordGroup[] = []
  keywordGroupFilter = ''
  showDeleted = false

  clauses: KeywordGroupClause[] = [
    new KeywordGroupClause(KeywordGroupClauseType.And)
  ]

  show () {
    this.$bvModal.show('keyword-group-filter-modal')
  }

  hidePopovers () {
    this.$root.$emit(PopoverEvent.Hide)
  }

  toggleGroup (id: number) {
    const index = this.selectedKeywordGroups.indexOf(id)

    if (index > -1) {
      return this.selectedKeywordGroups.splice(index, 1)
    }

    this.selectedKeywordGroups.push(id)
  }

  get applied () {
    const { filterValue: value } = this

    if (typeof value === 'string') {
      let clauses: KeywordGroupClause[] = []
      let numOfKeywordGroupsToSpot = 0
      let numOfKeywordGroupsToNotSpot = 0
      const filters = decodeURIComponent(value).split('~')[0].split('|')

      for (const filter of filters) {
        const [operand, ids] = filter.split('-')
        if (operand === FilterOperand.IncludesList) {
          const clause = new KeywordGroupClause(KeywordGroupClauseType.And)

          for (const id of ids.split(',')) {
            clause.addKeywordGroup(new KeywordGroup(id))
          }
          clauses.push(clause)
          numOfKeywordGroupsToSpot += clause.getNumOfKeywordGroups()
        }

        if (operand === FilterOperand.NIncludesList) {
          const clause = new KeywordGroupClause(KeywordGroupClauseType.Not)

          for (const id of ids.split(',')) {
            clause.addKeywordGroup(new KeywordGroup(id))
          }

          if (!clauses.find((clause) => {
            return clause.getType() === KeywordGroupClauseType.Not
          })) {
            clauses.push(clause)
            numOfKeywordGroupsToNotSpot = clause.getNumOfKeywordGroups()
          }
        }
      }
      if (clauses.length === 0) {
        clauses = [new KeywordGroupClause(KeywordGroupClauseType.And)]
      }

      return {
        clauses,
        numOfKeywordGroupsToSpot,
        numOfKeywordGroupsToNotSpot
      }
    }
    return {
      clauses: [
        new KeywordGroupClause(KeywordGroupClauseType.And)
      ],
      numOfKeywordGroupsToSpot: 0,
      numOfKeywordGroupsToNotSpot: 0
    }
  }

  async getKeywordGroups (ids: number[] = [], keywordGroupFilter = '', includeDeleted = false) {
    this.isLoading = true
    const keywordGroups = []
    const filterParams = []

    // If required, ignore any deleted keyword Groups (status of 410)
    if (!includeDeleted) {
      filterParams.push(`keywordGroupStatus-${FilterOperand.NotEquals}-value-410`)
    }
    // Get specified keyword Groups by ID, otherwise exclude any currently selected keyword Groups
    if (ids.length > 0) {
      filterParams.push(`keywordGroupId-${FilterOperand.In}-value-${ids.join(',')}`)
    } else if (this.selectedKeywordGroups.length > 0) {
      filterParams.push(`keywordGroupId-${FilterOperand.NotIn}-value-${this.selectedKeywordGroups.join(',')}`)
    }

    // Search for keyword groups using any user inputted text
    if (keywordGroupFilter) {
      filterParams.push(`keywordGroupName-${FilterOperand.IncludesI}-value-${keywordGroupFilter}`)
    }

    const headers = AuthUtil.isAuthenticated ? { headers: { 'x-auth-token': AuthUtil.accessToken } } : null
    const response = await axios({
      url: `${Api.Hub}/igrps/${this.installationId}/keyword-groups`,
      method: RequestMethod.GET,
      params: {
        limit: 100,
        filters: filterParams
      },
      ...headers
    })

    if (response && response.data) {
      const items = response.data.keywordGroups

      if (items) {
        for (const item of items) {
          keywordGroups.push(
            new KeywordGroup().fromApi(item)
          )
        }
      }
    }

    this.keywordGroups = keywordGroups
    this.isLoading = false

    return this.keywordGroups
  }

  async loadKeywordGroups (data: { keywordGroupFilter: string; showDeleted: boolean }) {
    if (this.keywordGroupFilter !== data.keywordGroupFilter) {
      this.keywordGroupFilter = data.keywordGroupFilter
    }

    if (this.showDeleted !== data.showDeleted) {
      this.showDeleted = data.showDeleted
    }

    await this.getKeywordGroups([], this.keywordGroupFilter, this.showDeleted)
  }

  get selectedKeywordGroupIds () {
    return this.clauses.flatMap((clause) => {
      return clause.getKeywordGroups().map((keywordGroup) => {
        return keywordGroup.getKeywordGroupId()
      })
    })
  }

  getClauseTypeButtonVariant (clauseType: KeywordGroupClauseType, buttonType: KeywordGroupClauseType) {
    if (clauseType === buttonType) {
      return 'primary'
    }
    return 'outline-primary'
  }

  hasNotClause () {
    return this.clauses.find((clause) => clause.isNotClause()) !== undefined
  }

  addClause (data: { keywordGroup: KeywordGroup }) {
    const clause = new KeywordGroupClause(KeywordGroupClauseType.And)
    clause.addKeywordGroup(data.keywordGroup)
    this.clauses.push(clause)

    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  removeClause (clause: KeywordGroupClause) {
    const index = this.clauses.indexOf(clause)
    if (index > -1) {
      this.clauses.splice(index, 1)
    }
  }

  async created () {
    this.isLoading = true
    this.clauses = this.applied.clauses

    // Enrich keyword groups from IDs
    if (this.selectedKeywordGroupIds.length > 0) {
      const keywordGroups = await this.getKeywordGroups(this.selectedKeywordGroupIds, '', true)
      for (const clause of this.clauses) {
        if (!clause) {
          continue
        }

        for (const clauseKeywordGroup of clause.getKeywordGroups()) {
          const keywordGroup = keywordGroups.find((keywordGroup) => keywordGroup.getKeywordGroupId() === clauseKeywordGroup.getKeywordGroupId())

          if (keywordGroup) {
            clauseKeywordGroup.enrich(keywordGroup)
          }
        }
      }
    }

    this.isLoading = false
    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  @Emit('apply')
  removeFilter () {
    return {
      callKeywordGroups: null
    }
  }

  @Emit('apply')
  doApply () {
    const filters = []

    for (const clause of this.clauses) {
      const keywordGroups = clause.getKeywordGroups()
      if (keywordGroups.length > 0) {
        const ids = keywordGroups.map((keywordGroup) => {
          return keywordGroup.getKeywordGroupId()
        })

        const operand = clause.getType() === KeywordGroupClauseType.And
          ? FilterOperand.IncludesList
          : FilterOperand.NIncludesList

        filters.push(`${operand}-${ids.join(',')}`)
      }
    }
    this.keywordGroupFilter = ''
    this.hidePopovers()
    this.$bvModal.hide('keyword-group-filter-modal')

    if (filters.length === 0) {
      return { callKeywordGroups: null }
    }

    return { callKeywordGroups: `${filters.join('|')}~${this.installationId}~kg` }
  }

  selectableKeywordGroups (clause: KeywordGroupClause) {
    const keywordGroups = clause.getKeywordGroups()
    const keywordGroupIds = keywordGroups.map((keywordGroup) => {
      return keywordGroup.getKeywordGroupId()
    })
    return this.sortedKeywordGroups.filter((keywordGroup) => {
      return !keywordGroupIds.includes(keywordGroup.getKeywordGroupId())
    })
  }

  get sortedKeywordGroups () {
    return this.keywordGroups.sort(
      (a, b) => {
        if (a.getKeywordGroupName() > b.getKeywordGroupName()) {
          return 1
        }

        if (a.getKeywordGroupName() < b.getKeywordGroupName()) {
          return -1
        }

        return 0
      }
    )
  }

  static toApiParams (filterValue: string) {
    if (typeof filterValue === 'string') {
      const [values] = decodeURIComponent(filterValue).split('~')

      return {
        filters: values.split('|').map((data) => {
          const [operand, value] = data.split('-')

          if (operand && value) {
            return `callKeywordGroups-${operand}-value-${decodeURIComponent(value)}`
          }
        })
      }
    }
  }
}
