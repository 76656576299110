
















































































































import { Component, Emit } from 'vue-property-decorator'
import {
  BModal,
  VBModal,
  BButton,
  BButtonGroup,
  BSpinner,
  BIconX,
  BIconPlus,
  BIconChevronDown,
  BIconXCircleFill
} from 'bootstrap-vue'
import { FilterOperand } from '@infinity/shared/helpers/filter'
import BaseFilterComponent from '@infinity/shared/components/BaseFilter.vue'
import { Api, RequestMethod } from '@infinity/shared/helpers/api'
import axios from 'axios'
import { PopoverEvent } from '@infinity/shared/components/layout/InfinityPopover.vue'
import WebComponent from '@infinity/shared/decorators/WebComponent'
import { AuthUtil } from '@infinity/shared/utils/auth'
import AddKeyword from '@infinity/shared/components/AddKeyword.vue'

export class Keyword {
  private id = 0
  private phrase = ''
  private keywordGroupName = ''
  private leg = ''
  private status = 0

  constructor (id = '0') {
    this.id = parseInt(id)
  }

  getId (): number {
    return this.id
  }

  getPhrase (): string {
    return this.phrase
  }

  getKeywordGroupName (): string {
    return this.keywordGroupName
  }

  getLeg (): string {
    return this.leg
  }

  getStatus (): number {
    return this.status
  }

  isDeleted (): boolean {
    return this.status === 410
  }

  enrich (keyword: Keyword) {
    this.phrase = keyword.getPhrase()
    this.keywordGroupName = keyword.getKeywordGroupName()
    this.leg = keyword.getLeg()
    this.status = keyword.getStatus()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromApi (model: any): Keyword {
    this.id = parseInt(model.keywordId)
    this.phrase = model.keyword
    this.keywordGroupName = model.keywordGroupName
    this.leg = model.leg
    this.status = parseInt(model.keywordStatus)

    return this
  }
}

export enum KeywordClauseType {
  And,
  Not
}

export class KeywordClause {
  private keywords: Keyword[] = []
  private type: KeywordClauseType

  constructor (type: KeywordClauseType) {
    this.type = type
  }

  addKeyword (keyword: Keyword) {
    const index = this.keywords.indexOf(keyword)
    if (index === -1) {
      this.keywords.push(keyword)
    }
  }

  hasKeyword (keyword: Keyword) {
    return this.keywords.indexOf(keyword) > -1
  }

  removeKeyword (keyword: Keyword) {
    const index = this.keywords.indexOf(keyword)
    if (index > -1) {
      this.keywords.splice(index, 1)
    }
  }

  getKeywords () {
    return this.keywords
  }

  setType (type: KeywordClauseType) {
    this.type = type
  }

  getType () {
    return this.type
  }

  getNumOfKeywords () {
    return this.keywords.length
  }

  isAndClause () {
    return this.type === KeywordClauseType.And
  }

  isNotClause () {
    return this.type === KeywordClauseType.Not
  }
}

@WebComponent('filter-keyword')
@Component({
  components: {
    BModal,
    BButton,
    BButtonGroup,
    BSpinner,
    BIconX,
    BIconPlus,
    BIconChevronDown,
    BIconXCircleFill,
    AddKeyword
  },
  directives: {
    'b-modal': VBModal
  }
})
export default class KeywordSpottingFilterComponent extends BaseFilterComponent {
  static filterName = 'Keyword'
  readonly KeywordClauseType = KeywordClauseType

  isLoading = false
  isLoadingKeywords = false
  keywords: Keyword[] = []
  keywordFilter = ''
  showDeleted = false

  clauses: KeywordClause[] = [
    new KeywordClause(KeywordClauseType.And)
  ]

  show () {
    this.$bvModal.show('keyword-filter-modal')
  }

  hidePopovers () {
    this.$root.$emit(PopoverEvent.Hide)
  }

  async created () {
    this.isLoading = true

    this.clauses = this.applied.clauses

    // Enrich keywords from IDs
    if (this.selectedKeywordIds.length > 0) {
      const keywords = await this.getKeywords(this.selectedKeywordIds, '', true)
      for (const clause of this.clauses) {
        if (!clause) {
          continue
        }

        for (const clauseKeyword of clause.getKeywords()) {
          const keyword = keywords.find((keyword) => keyword.getId() === clauseKeyword.getId())

          if (keyword) {
            clauseKeyword.enrich(keyword)
          }
        }
      }
    }

    this.isLoading = false
    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  get applied () {
    const { filterValue: value } = this

    if (typeof value === 'string') {
      let clauses: KeywordClause[] = []
      let numOfKeywordsToSpot = 0
      let numOfKeywordsToNotSpot = 0

      // Split filters into AND and NOT clauses
      const filters = decodeURIComponent(value).split('~')[0].split('|')
      for (const filter of filters) {
        const [operand, ids] = filter.split('-')

        if (operand === FilterOperand.IncludesList) {
          const clause = new KeywordClause(KeywordClauseType.And)

          for (const id of ids.split(',')) {
            clause.addKeyword(new Keyword(id))
          }

          clauses.push(clause)
          numOfKeywordsToSpot += clause.getNumOfKeywords()
        } else if (operand === FilterOperand.NIncludesList) {
          const clause = new KeywordClause(KeywordClauseType.Not)

          for (const id of ids.split(',')) {
            clause.addKeyword(new Keyword(id))
          }

          if (!clauses.find((clause) => { return clause.getType() === KeywordClauseType.Not })) {
            clauses.push(clause)
            numOfKeywordsToNotSpot = clause.getNumOfKeywords()
          }
        }
      }

      if (clauses.length === 0) {
        clauses = [new KeywordClause(KeywordClauseType.And)]
      }

      return {
        clauses,
        numOfKeywordsToSpot,
        numOfKeywordsToNotSpot
      }
    }

    // Set defaults
    return {
      clauses: [
        new KeywordClause(KeywordClauseType.And)
      ],
      numOfKeywordsToSpot: 0,
      numOfKeywordsToNotSpot: 0
    }
  }

  get selectedKeywordIds () {
    return this.clauses.flatMap((clause) => {
      return clause.getKeywords().map((keyword) => {
        return keyword.getId()
      })
    })
  }

  get sortedKeywords () {
    return this.keywords.sort(
      (a, b) => {
        if (a.getPhrase() > b.getPhrase()) {
          return 1
        }

        if (a.getPhrase() < b.getPhrase()) {
          return -1
        }

        return 0
      }
    )
  }

  getClauseTypeButtonVariant (clauseType: KeywordClauseType, buttonType: KeywordClauseType) {
    if (clauseType === buttonType) {
      return 'primary'
    } else {
      return 'outline-primary'
    }
  }

  hasNotClause () {
    return this.clauses.find((clause) => clause.isNotClause()) !== undefined
  }

  addClause (data: { keyword: Keyword }) {
    const clause = new KeywordClause(KeywordClauseType.And)
    clause.addKeyword(data.keyword)
    this.clauses.push(clause)

    this.$nextTick(
      () => {
        this.$forceUpdate()
      }
    )
  }

  removeClause (clause: KeywordClause) {
    const index = this.clauses.indexOf(clause)
    if (index > -1) {
      this.clauses.splice(index, 1)
    }
  }

  async getKeywords (ids: number[] = [], keywordFilter = '', includeDeleted = false) {
    this.isLoadingKeywords = true

    const keywords = []
    const filterParams = []

    // If required, ignore any deleted keywords (status of 410)
    if (!includeDeleted) {
      filterParams.push(`keywordStatus-${FilterOperand.NotEquals}-value-410`)
    }

    // Get specified keywords by ID, otherwise exclude any currently selected keywords
    if (ids.length > 0) {
      filterParams.push(`keywordId-${FilterOperand.In}-value-${ids.join(',')}`)
    } else if (this.selectedKeywordIds.length > 0) {
      filterParams.push(`keywordId-${FilterOperand.NotIn}-value-${this.selectedKeywordIds.join(',')}`)
    }

    // Search for keywords using any user inputted text
    if (keywordFilter) {
      filterParams.push(`keyword-${FilterOperand.IncludesI}-value-${keywordFilter}`)
    }

    const headers = AuthUtil.isAuthenticated ? { headers: { 'x-auth-token': AuthUtil.accessToken } } : null
    const response = await axios({
      url: `${Api.Hub}/igrps/${this.installationId}/keywords`,
      method: RequestMethod.GET,
      params: {
        limit: 100,
        filters: filterParams
      },
      ...headers
    })

    if (response && response.data) {
      const items = response.data.keywords

      if (items) {
        for (const item of items) {
          keywords.push(
            new Keyword().fromApi(item)
          )
        }
      }
    }

    this.keywords = keywords
    this.isLoadingKeywords = false

    return keywords
  }

  async loadKeywords (data: { keywordFilter: string; showDeleted: boolean }) {
    if (this.keywordFilter !== data.keywordFilter) {
      this.keywordFilter = data.keywordFilter
    }

    if (this.showDeleted !== data.showDeleted) {
      this.showDeleted = data.showDeleted
    }

    await this.getKeywords([], this.keywordFilter, this.showDeleted)
  }

  @Emit('apply')
  removeFilter () {
    return {
      keyword: null
    }
  }

  @Emit('apply')
  doApply () {
    const filters = []

    for (const clause of this.clauses) {
      const keywords = clause.getKeywords()
      if (keywords.length > 0) {
        const ids = keywords.map((keyword) => {
          return keyword.getId()
        })

        const operand = clause.getType() === KeywordClauseType.And
          ? FilterOperand.IncludesList
          : FilterOperand.NIncludesList

        filters.push(`${operand}-${ids.join(',')}`)
      }
    }

    this.keywordFilter = ''
    this.hidePopovers()
    this.$bvModal.hide('keyword-filter-modal')

    if (filters.length === 0) {
      return { keyword: null }
    }

    return { keyword: `${filters.join('|')}~${this.installationId}~k` }
  }

  static toApiParams (filterValue: string) {
    if (typeof filterValue === 'string') {
      const [values] = decodeURIComponent(filterValue).split('~')

      return {
        filters: values.split('|').map((data) => {
          const [operand, value] = data.split('-')

          if (operand && value) {
            return `callKeywords-${operand}-value-${decodeURIComponent(value)}`
          }
        })
      }
    }
  }
}
